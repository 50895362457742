import cookieStorage from 'cookie-storage'
import localStorage from 'local-storage'
import { usePathname, useSearch } from 'router'
import { constants } from 'helpers'
import { getReferrerLandingPageUrl } from 'helpers/getters'
import { useLocalePreferences } from 'modules/localePreferences'
import { useSubscribeOffer } from 'modules/subscription'

import useOnce from './useOnce'


const cookiesToDelete = [
  constants.cookieNames.country,
  constants.cookieNames.discountCoupon,
  constants.cookieNames.appliedCouponCode,
  constants.cookieNames.isFreeTrialCoupon,
]

type UseLandingSetupProps = {
  couponCode?: string
  cookies?: Record<string, any>
  country?: Intl.CountryCode
  isFreeTrial?: boolean
  skip?: boolean
}

const useLandingSetup = ({ couponCode, cookies, country: customCountry, isFreeTrial, skip }: UseLandingSetupProps) => {
  const pathname = usePathname()
  const search = useSearch()
  const subscribeOffer = useSubscribeOffer()
  const { forceUpdate } = subscribeOffer
  const { localePreferences } = useLocalePreferences()
  const defaultCountry = localePreferences.country
  const country = customCountry || defaultCountry

  useOnce(() => {
    if (skip) {
      return
    }

    cookiesToDelete.forEach((cookieName) => {
      cookieStorage.removeItem(cookieName)
    })

    if (cookies) {
      Object.entries(cookies).forEach(([ key, value ]) => {
        cookieStorage.setSessionItem(key, value)
      })
    }

    if (couponCode !== undefined) {
      cookieStorage.setSessionItem(constants.cookieNames.discountCoupon, couponCode)

      if (couponCode === 'FREE_TRIAL') {
        cookieStorage.setSessionItem(constants.cookieNames.isFreeTrialCoupon, true)
      }
    }

    cookieStorage.setSessionItem(constants.cookieNames.logoRedirectUrl, `${pathname}${search}`)

    cookieStorage.setSessionItem(constants.cookieNames.country, country)

    if ( __CLIENT__) {
      // we remove previously saved shipping form values, to reset the country
      localStorage.removeItem(constants.localStorageNames.shippingFormValues)

      // force update if offer is changed
      setTimeout(() => {
        forceUpdate()
      })
    }

    const referrerLandingPageURL = getReferrerLandingPageUrl(pathname)

    cookieStorage.setItem(constants.cookieNames.referrerLandingPageURL, referrerLandingPageURL, {
      sameSite: 'none', // may be used in GTM
    })
  }, [ skip, couponCode, country ])

  return subscribeOffer
}


export default useLandingSetup
