import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import { useIntl } from 'intl'
import dayjs from 'date'
import cookieStorage from 'cookie-storage'


type UseCountdownValues = {
  cookieName?: string
  message: Intl.Message
  nodeRef: React.RefObject<HTMLElement>
  expiredMessage?: Intl.Message
  initialTime?: number
  format?: string // dayjs duration format
  interval?: number
  withMilliseconds?: boolean
}

type GetMessageProps = Pick<UseCountdownValues, 'message' | 'format'> & {
  timeLeft: React.RefObject<number>
  formatMessage: (message: string | Intl.MessageTranslation, values?: Intl.MessageValues) => string
}

const getText = (props: GetMessageProps): string => {
  const { formatMessage, message, timeLeft, format } = props
  const { values, ...rest } = message

  const duration = dayjs.duration(timeLeft.current)
  const time = duration.format(
    format
      .replace('HH', `[${Math.floor(duration.asHours()).toString().padStart(2, '0')}]`)
      .replace('S', `[${(duration.milliseconds() / 100).toFixed(0)}]`)
  )

  return formatMessage(rest, {
    ...values,
    time,
  })
}

const useIsomorphicLayoutEffect = __CLIENT__ ? useLayoutEffect : useEffect

const useCountdown = (values: UseCountdownValues) => {
  const {
    message, expiredMessage, nodeRef, cookieName,
    format = 'mm:ss', withMilliseconds = false,
    initialTime = 20 * 60 * 1000, // 20 minutes
  } = values

  const interval = withMilliseconds ? 100 : 1000
  const intl = useIntl()

  const timeLeft = useRef(0)
  const lastTickTime = useRef(Date.now())

  const [ isExpired, setExpired ] = useState(() => {
    const cookieTime = cookieName ? cookieStorage.getItem<number>(cookieName) : null
    timeLeft.current = Number(cookieTime ?? initialTime)

    // ATTN save to force SSR and client sync, because cookie can be changed on the client side
    if (cookieTime && __SERVER__) {
      cookieStorage.setItem(cookieName, timeLeft.current)
    }

    return timeLeft.current <= interval
  })

  useEffect(() => {
    const cookieTime = cookieName ? cookieStorage.getItem<number>(cookieName) : null
    timeLeft.current = Number(cookieTime ?? initialTime)

    setExpired(timeLeft.current <= interval)
  }, [ cookieName, initialTime, interval ])

  useIsomorphicLayoutEffect(() => {
    if (!nodeRef || isExpired) {
      // You can not send a ref link if the timer is expired
      return
    }

    // Adjusts the timer when the tab becomes active to account for elapsed time during inactivity (PF-2166)
    const syncTimeLeft = () => {
      const now = Date.now()
      const elapsedTime = now - lastTickTime.current
      timeLeft.current -= elapsedTime
      lastTickTime.current = now
      if (timeLeft.current < 0) {
        timeLeft.current = 0
      }
      update()
    }

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        syncTimeLeft()
      }
    }

    const update = () => {
      if (nodeRef.current) {
        nodeRef.current.innerHTML = getText({
          message,
          timeLeft,
          format,
          formatMessage: intl.formatMessage,
        })
      }
    }

    const tick = () => {
      const now = Date.now()
      const elapsedTime = now - lastTickTime.current
      timeLeft.current -= elapsedTime
      lastTickTime.current = now

      if (timeLeft.current <= 0) {
        timeLeft.current = 0
        clearInterval(intervalId)
        setExpired(true)
        cookieStorage.setItem(cookieName, 0, { maxAge: 24 * 60 * 60 * 1000 })
        return
      }

      if (cookieName) {
        if (saveTicks > 0) {
          saveTicks--
        }
        else {
          cookieStorage.setItem(cookieName, timeLeft.current, { maxAge: 24 * 60 * 60 * 1000 })
          // save each 5 seconds
          saveTicks += 5 * 1000 / interval
        }
      }

      update()
    }

    let intervalId = setInterval(tick, interval)
    let saveTicks = 0
    lastTickTime.current = Date.now()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(intervalId)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [ cookieName, initialTime, format, interval, intl, isExpired, message, nodeRef ])

  const countdownMessage = isExpired && expiredMessage
    ? intl.formatMessage(expiredMessage)
    : getText({
      message,
      timeLeft,
      format,
      formatMessage: intl.formatMessage,
    })

  return {
    isExpired,
    countdownMessage,
  }
}


export default useCountdown
